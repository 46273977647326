import React from 'react';
import { Contact } from '../components/'
import { Page } from '../components/shared';
import './main.css';

const App = () => {
  return (
    <main>
      <Page>
        <Contact/>
      </Page>
    </main>
  )
}

export default App
